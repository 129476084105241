export interface IAlertFilterParams {
  monthRange?: string[];
  dateType?: string;
  territoryType?: string;
  territory?: string | null;
  territoryIds?: string[];
  crossing?: string;
  size?: string;
  authorization?: string;
  activeLayers?: string[];
  embargoed?: string;
  deforestationClasses?: string[];
  actionTypesIds?: string[];
  sources?: string[];
  locationType?: string;
  locationText?: string;
}

const DEFAULT_VALUE: IAlertFilterParams = {
  monthRange: ['2019-01', '2020-03'],
  dateType: 'detected_at',
  territoryType: 'all',
  territory: null,
  territoryIds: [],
  crossing: 'all',
  size: 'all',
  authorization: 'all',
  activeLayers: [],
  embargoed: 'all',
  deforestationClasses: ['All'],
  actionTypesIds: ['All'],
  sources: ['All'],
};

export default DEFAULT_VALUE;
