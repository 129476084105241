import _ from 'lodash';

import { getModule } from './tenant';

export type TModules = 'brazil' | 'indonesia';

export type ILocaleData = {
  portugueseContent: string;
  englishContent: string;
  indonesianContent?: string;
};

type TModuleCoordinates = {
  [key in TModules]: {
    lat: number;
    lng: number;
  };
};

type TModuleServerLayers = {
  [key in TModules]: {
    prefix: string;
    production: string;
    default: string;
  };
};

const moduleLanguage: IStringObject = {
  brazil: 'pt-BR',
  indonesia: 'id',
};

const localeContentField: IStringObject = {
  'pt-BR': 'portugueseContent',
  en: 'englishContent',
  id: 'indonesianContent',
};

const moduleCoordinates: TModuleCoordinates = {
  brazil: {
    lat: -14.392118083661728,
    lng: -56.25000000000001,
  },
  indonesia: {
    lat: -2.5,
    lng: 118,
  },
};

const moduleServerLayers: TModuleServerLayers = {
  brazil: {
    prefix: 'mapbiomas-alertas',
    production: 'dashboard-alerts',
    default: 'dashboard-alerts-staging',
  },
  indonesia: {
    prefix: 'mapbiomas-alerta-indonesia',
    production: 'dashboard-alerts-staging-indonesia',
    default: 'dashboard-alerts-staging-indonesia',
  },
};

export const CURRENT_MODULE = getModule();
export const DEFAULT_LANGUAGE = moduleLanguage[getModule()] || 'pt-BR';
export const MODULE_COORDINATES = moduleCoordinates[CURRENT_MODULE];
export const MODULE_SERVER_LAYER =
  _.get(moduleServerLayers[CURRENT_MODULE], String(process.env.NODE_ENV)) || moduleServerLayers[CURRENT_MODULE].default;
export const MODULE_SERVER_LAYER_PREFIX = moduleServerLayers[CURRENT_MODULE].prefix;

export const isModule = (module: string | string[]) => {
  if (Array.isArray(module)) {
    return module.includes(getModule());
  }
  return getModule() === module;
};

export const getLocaleContentField = (data?: any) => {
  if (!data) return;
  const fallbackLocale = isModule('brazil') ? 'pt-BR' : 'en';
  const locale = localStorage.getItem('locale') || fallbackLocale;
  return data[localeContentField[locale]] || data[localeContentField[fallbackLocale]];
};
