import { TModules } from './locale';

type TModulePrefix = {
  [key in TModules]?: string[];
};

type TConfig = {
  [key in TModules]: {
    app_host?: string;
    api_url?: string;
    elasticsearch_host?: string;
    geoserver_1_url?: string;
    geoserver_2_url?: string;
    alert_images_host?: string;
    elasticsearch_secret_key?: string;
    inspection_monitor_url?: string;
    inspection_monitor_url_en?: string;
  };
};

const config: TConfig = {
  brazil: {
    app_host: process.env.REACT_APP_BRA_APP_HOST,
    api_url: process.env.REACT_APP_BRA_API_URL,
    elasticsearch_host: process.env.REACT_APP_BRA_ELASTICSEARCH_HOST,
    geoserver_1_url: process.env.REACT_APP_BRA_GEOSERVER_1_URL,
    geoserver_2_url: process.env.REACT_APP_BRA_GEOSERVER_2_URL,
    alert_images_host: process.env.REACT_APP_BRA_ALERT_IMAGES_HOST,
    elasticsearch_secret_key: process.env.REACT_APP_BRA_ELASTICSEARCH_SECRET_KEY,
    inspection_monitor_url: process.env.REACT_APP_BRA_INSPECTION_MONITOR_URL,
    inspection_monitor_url_en: process.env.REACT_APP_BRA_INSPECTION_MONITOR_URL_EN,
  },
  indonesia: {
    app_host: process.env.REACT_APP_IND_APP_HOST,
    api_url: process.env.REACT_APP_IND_API_URL,
    elasticsearch_host: process.env.REACT_APP_IND_ELASTICSEARCH_HOST,
    geoserver_1_url: process.env.REACT_APP_IND_GEOSERVER_1_URL,
    geoserver_2_url: process.env.REACT_APP_IND_GEOSERVER_2_URL,
    alert_images_host: process.env.REACT_APP_IND_ALERT_IMAGES_HOST,
    elasticsearch_secret_key: process.env.REACT_APP_IND_ELASTICSEARCH_SECRET_KEY,
    inspection_monitor_url: process.env.REACT_APP_IND_INSPECTION_MONITOR_URL,
    inspection_monitor_url_en: process.env.REACT_APP_IND_INSPECTION_MONITOR_URL_EN,
  },
};

export function getEnv() {
  return process.env.NODE_ENV || 'development';
}

export function getModule(): TModules {
  const hostArr = window.location.host.split('.');
  const defaultModule = 'brazil';
  const modulePrefixes: TModulePrefix = {
    indonesia: ['ind', 'idn', 'indonesia'],
  };
  for (const module of Object.keys(modulePrefixes) as TModules[]) {
    if (modulePrefixes[module]?.some((prefix) => hostArr.includes(prefix))) return module;
  }
  return defaultModule;
}

export function getEnvVar(varName: keyof typeof config.brazil) {
  return config[getModule()][varName];
}
