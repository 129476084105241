import {
  getCurrentTimeFromWorldClock,
  getUserIdFromToken,
} from '../helpers/auth'

export default {
  currentUserId: async (_object: any, _args: any, _context: any, _info: any) => {
    if (localStorage.getItem('token')) {
      let currentTime = await getCurrentTimeFromWorldClock()
      let userId = getUserIdFromToken(currentTime)
      return userId
    }

    return undefined
  }
}
