import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import _ from 'lodash';
import moment from 'moment';

import initialAlertFilterParams from '../../assets/data/initialAlertFilterParams';
import initialLocationFilterParams from '../../assets/data/initialLocationFilterParams';
import { MODULE_COORDINATES } from '../../helpers/locale';
import { getEnv, getEnvVar } from '../../helpers/tenant';
import { GET_USER } from '../../models/User/operations';
import resetToken from './helpers/resetToken';
import { MutationResolver, QueryResolver } from './local-resolvers';

const cache = new InMemoryCache();

cache.writeData({
  data: {
    app: {
      __typename: 'AppState',
      activeAlertPosition: null,
      activeAlertProperties: null,
      activeAlertCode: null,
      activeAlertBoxTab: 'details',
      activeMapbiomasPeriodKey: '2023',
      activePlanetPeriodKey: moment()
        .subtract(moment().date() > 10 ? 1 : 2, 'month')
        .format('YYYY-MM'),
      activeFiltersTab: 'default',
      alertsListIsVisible: false,
      isLoadingAlertData: false,
      activeTerritoryAlert: null,
      alertIsVisible: false,
      mapFlyTo: null,
      mapPointInfo: null,
      maxMonth: null,
      minMonth: null,
      layersOpacity: 70,
      filtersAreVisible: true,
      mobileMenuIsVisible: false,
      statisticsAreVisible: true,
      hasAlertData: false,
      isLoadingBaseData: true,
      isDoingBatchExportReports: false,
      notifyUserNewTermOfUseVersion: false,
      openTourGuided: false,
      totalOfAlerts: null,
      showOnlyNewlyAlerts: false,
      defaultFilterParams: {
        __typename: 'AppDefaultFilterParamsState',
        ...initialAlertFilterParams,
        ...initialLocationFilterParams,
        activeAlertCode: null,
        activeBaseMap: 6,
        activeLayers: ['estados'],
        map: `${MODULE_COORDINATES.lat},${MODULE_COORDINATES.lng},4`,
      },
      filterParams: {
        __typename: 'AppFilterParamsState',
        ...initialAlertFilterParams,
        ...initialLocationFilterParams,
        activeAlertCode: null,
        activeBaseMap: 7,
        activeLayers: [],
        map: `${MODULE_COORDINATES.lat},${MODULE_COORDINATES.lng},4`,
      },
      statisticsParams: {
        __typename: 'AppStatisticsParamsState',
        monthRange: ['', ''],
        territoryType: 'country',
        territory: 1,
        territoryLabel: 'Brasil',
        territoryIds: [],
        dataType: 'area',
      },
      showAlertFiltersWarning: false,
    },
    toast: {
      __typename: 'ToastState',
      content: null,
      severity: null,
      visible: false,
    },
  },
});

const logErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log('[GraphQL error]: Message: ', message, ', Location: ', locations, ', Path: ', path)
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const uploadLink = createUploadLink({
  uri: getEnvVar('api_url') + '/client/v2/graphql',
});

const withToken = setContext((_, previousContext) => {
  let token = localStorage.getItem('token');
  if (token === null) {
    return previousContext;
  }

  return {
    ...previousContext,
    headers: {
      ...(previousContext && previousContext.headers),
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const resetTokenOnUnauthorized = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors && _.some(graphQLErrors, (e) => e.extensions && e.extensions.forbidden)) {
    if (operation.operationName !== _.get(GET_USER, 'definitions[0].name.value')) {
      // So we don't reset the store when it's not necessary
      if (localStorage.getItem('token')) {
        client.resetStore();
      }
    }
    resetToken();
  }
});

const client = new ApolloClient({
  name: 'Plataforma Alerta Client',
  version: '2.0',
  cache,
  link: ApolloLink.from(
    _.compact([withToken, resetTokenOnUnauthorized, getEnv() === 'development' ? logErrors : null, uploadLink])
  ),
  resolvers: { Query: QueryResolver, Mutation: MutationResolver },
});

export default client;
