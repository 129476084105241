import resetToken from './resetToken';

export function namespacedTokenKey() {
  let splitLocation = window.location.pathname.split('/');

  return `${splitLocation[1]}/token`;
}

export async function getCurrentTimeFromWorldClock() {
  let currentTime = null;
  try {
    let response = await fetch('https://worldtimeapi.org/api/timezone/America/Sao_Paulo');
    let json = await response.json();

    if (!json.utc_datetime) {
      throw Error('World clock API has returned a valid json but with an unexpected format');
    }

    currentTime = new Date(json.utc_datetime);
  } catch (e) {
    console.warn(e);
    currentTime = new Date();
  }

  return currentTime;
}

function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function getUserIdFromToken(currentTime = new Date()) {
  try {
    let token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    let claims = parseJwt(token);

    let expirationDate = new Date(claims.exp * 1000);

    if (currentTime.getTime() > expirationDate.getTime()) {
      resetToken();
      window.location.href = '/';
      return null;
    }

    return claims.sub;
  } catch (e) {
    resetToken();
    window.location.href = '/';
    return null;
  }
}
