import MomentUtils from '@date-io/moment';
import flatten from 'flat';
import numbro from 'numbro';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import { withSearchkit } from 'searchkit/client';

import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { ToastContainer } from '../../components/ToastContainer';
import { DEFAULT_LANGUAGE } from '../../helpers/locale';
import client from '../apollo-client-v2';
import localeMessages from '../locales/locale_messages.yml';
import Routes from '../routes';
import theme from '../themes/plataformaAlerta';

numbro.registerLanguage({
	languageTag: 'pt-BR',
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'mil',
		million: 'milhões',
		billion: 'b',
		trillion: 't',
	},
	ordinal: () => 'º',
	currency: {
		symbol: 'R$ ',
		position: 'prefix',
	},
});

numbro.setLanguage('pt-BR');

class App extends React.Component {
	render() {
		const locale = localStorage.getItem('locale') || DEFAULT_LANGUAGE;

		return (
			<ApolloProvider client={client}>
				<ThemeProvider theme={theme}>
					<StylesProvider injectFirst>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<IntlProvider
								key={locale}
								locale={locale}
								messages={flatten(localeMessages[locale])}
							>
								<Routes />
								<ToastContainer />
							</IntlProvider>
						</MuiPickersUtilsProvider>
					</StylesProvider>
				</ThemeProvider>
			</ApolloProvider>
		);
	}
}

export default withSearchkit(App);
