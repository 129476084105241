import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './LoadingPage.module.scss';

export function LoadingBasePage() {
  const [loadingHeight, setLoadingHeight] = useState('100%');

  useEffect(() => {
    setLoadingHeight(window.innerHeight - 80 + 'px');
  }, []);

  return (
    <div>
      <header className={styles.header}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={4} sm={4}>
            <Grid container alignItems='center'>
              <Grid item>
                <Skeleton animation='wave' variant='text' width='100px' height={45} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={3}></Grid>
          <Grid item xs={6} sm={5}>
            <Grid container alignItems='center' spacing={3}>
              <Grid item xs={3}>
                <Skeleton animation='wave' variant='text' width='100%' height={36} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation='wave' variant='text' width='100%' height={36} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation='wave' variant='text' width='100%' height={36} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation='wave' variant='text' width='100%' height={36} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*
          <Grid container spacing={ 3 } alignItems="center" className={styles.headerButtonContent}>
          <Grid item sm={ 2 }>
          </Grid>
          <Grid item sm={ 5 }>
            <Grid container justifyContent="flex-start"alignItems="center" spacing={ 3 }>
              <Grid item>
                <Skeleton animation="wave" variant="circle" width='20px'  height={ 20 } />
              </Grid>
              <Grid item>
                <Skeleton animation="wave" variant="text" width='2px' height={ 25 } />
              </Grid>
              <Grid item>
                <Skeleton animation="wave" variant="circle" width='20px' height={ 20 } />
              </Grid>
              <Grid item>
                <Skeleton animation="wave" variant="circle" width='20px' height={ 20 } />
              </Grid>
              <Grid item>
                <Skeleton animation="wave" variant="circle" width='20px' height={ 20 } />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={ 5 }>
            <Grid container justifyContent="flex-end"alignItems="center" spacing={ 3 }>
              <Grid item xs={ 2 }>
                <Skeleton animation="wave" variant="text" width='100%'  height={ 30 } />
              </Grid>
              <Grid item xs={ 2 }>
                <Skeleton animation="wave" variant="text" width='100%' height={ 30 } />
              </Grid>
              <Grid item xs={ 2 }>
                <Skeleton animation="wave" variant="text" width='100%' height={ 30 } />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
           */}
      </header>
      <Grid container alignItems='center' className={styles.loading} style={{ height: loadingHeight }}></Grid>
      <footer>
        <Grid container spacing={3} alignItems='center' alignContent={'center'}>
          <Grid item sm={4}>
            <Skeleton animation='wave' variant='text' width='100%' height={20} />
          </Grid>
          <Grid item sm></Grid>
          <Grid item sm={2}>
            <Skeleton animation='wave' variant='text' width='100%' height={20} />
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}
